<template>
  <div
    class="py-16 bg-grey-light min-h-full"
  >
    <v-inner-header
      :hide-close="true"
    />
    <div
      v-if="isAuth && user.company"
      class="text-center pt-24 "
    >
      <h1>Hello, {{ user.company.name }}.</h1>
      <br>
      <p class="leading-normal">
        You have already logged in.<br>
        Go to your dashboard
        <router-link
          to="/"
          class="underline text-green"
        >
          here
        </router-link>
        or
        <a
          href="#"
          @click="logout"
        > log out</a>.
      </p>
    </div>
    <div
      v-if="!isAuth"
      class="w-full mt-10 px-4"
    >
      <account-details
        v-if="step === 'create-account'"
        :form.sync="form"
        :loading.sync="form.loading"
        @create="onRegisterAccount"
      />
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import storage from '@/storage'
import AccountDetails from '@/views/RegisterSteps/CreateAccountDetails'
import CompanyInfo from '@/views/RegisterSteps/CompanyInfo'
import CompanyTax from '@/views/RegisterSteps/CompanyTax'
import CompanyInvoicing from '@/views/RegisterSteps/CompanyInvoicing'

export default {
  name: 'Register',
   components: {
    AccountDetails,
    CompanyInfo,
    CompanyTax,
    CompanyInvoicing
  },
  data(){
    return {
      stepNumber: 1,
      step: 'create-account',
      form: {
        type: 'account',
        userId: null,
        googleId: null,
        name: '',
        email: '',
        password: '',
        companyName: '',
        companyAddress: '',
        companyEmail: '',
        companyCountry: '',
        companyPostalCode: '',
        companyCity: '',
        companyPhone: '',
        selectedIndustry: 'Wedding',
        selectedCurrency: 1,
        selectedTimezone: 'Europe/Amsterdam',
        useTax: false,
        mainTaxRate: '21',
        includeTaxInPrice: false,
        invoiceValidPeriod: '14',
        proposalValidPeriod: '30',
        invoiceNumberPrefix: '',
        emailSignature: '',
        saving: false,
        loading: false,
        loadingStep: 1,
        refcode: '',
        discountCode: '',
        ipAddress: '',
        userAgent: navigator.userAgent,
      },
      isAuth: auth.check(),
      user: auth.user()
    }
  },
  async mounted() {

    if(this.$route.query.refcode){
      storage.set('refcode', this.$route.query.refcode)
    }

    if(this.$route.query.dc){
      storage.set('discountCode', this.$route.query.dc)
    }

    if(storage.has('refcode')){
      this.form.refcode = storage.get('refcode')
    }

    if(storage.has('discountCode')){
      this.form.discountCode = storage.get('discountCode')
    }

    fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
      this.form.ipAddress = ip
    })
  },
  methods: {
    async logout() {
      this.$toasted.clear()

      try {
        this.$store.dispatch('auth/logout')
      } catch(e) {
        this.$toasted.global.api_error(e)
      }

      window.location.reload(true)
    },
    async onRegisterAccount(){

      try {
        const { data } = await this.$api.get('register').emailCheck(this.form)
        if(data){
          this.$toasted.global.general_error({
            message : data
          })
          return
        }
      } catch(e){
        this.$toasted.global.general_error({
          message : e
        })
      }

      this.form.saving = true
      this.form.loading = false
      this.$toasted.clear()

      try {
        await this.$store.dispatch('auth/createUser', this.form)

        await this.$store.dispatch('auth/login', {
          type: this.form.type,
          email: this.form.email,
          password: this.form.password,
          googleId: this.form.googleId,
          rememberMe: true
        })

        this.form.loading = true
        this.form.saving = false

        await auth.refreshUser()

        this.$gtag.event('register', {
          'event_category': 'user',
          'event_label': 'finish',
          'value': 1,
        })

        this.$router.push({name: 'RegisterCompany' })

      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
  },
}
</script>
